<template>
  <div>
    <NavBarFree :custom="colorsConfig" :widget-team="$route.params.team" is-stadium-visible />
    <div class="container">
      <BetsTool
        id="bet-tool"
        v-observe-visibility="visibilityChanged"
        :colors-config="colorsConfig"
        :is-premium-user="!!user.premiumAccount"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { widgetSeccionVista } from '@/utils/analytics';
import { mapGetters, mapState } from 'vuex';
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

export default {
  name: 'Bets',
  components: {
    BetsTool: () => import('@/components/Sections/GolTipster/BetsTool'),
    NavBarFree: () => import('@/components/NavBars/NavBarFree'),
  },
  data() {
    return {
      colorsConfig: {
        logo: 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png',
        color: '#FFFFFF',
        headerBackground: '#242424',
        headerColor: '#FFFFFF',
        headerBorderColor: '##6EB913',
        header2Background: '#3b3b3b',
        header2Color: '#FFFFFF',
        subHeaderBackground: '#cce8b5',
        subHeaderColor: '#353535',
        type: 1,
        team_id: 0,
        tournaments: false,
        categories: 'all',
      },
    };
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapGetters('loginGeneral', ['isLoggedIn', 'getIsGolstats']),
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        const sectionName = entry.target.id;
        widgetSeccionVista({
          value: sectionName,
          team: this.$route.params.team,
          is_premium: this.user.premiumAccount,
          golstats: this.getIsGolstats,
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  max-width: 1268px;
  height: auto;
  background: white;
}
</style>

<style>
body {
  padding-top: 0 !important;
}
</style>
